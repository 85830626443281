<template>
    <div>
        <el-main>
            <el-button type="primary" style="margin-bottom: 10px" size="small" @click="toDetail()">+添加技工</el-button>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="类型：">
                    <el-select v-model="searchForm.type" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="平台技工" :value="1"></el-option>
                        <el-option label="小区技工" :value="2"></el-option>
                        <el-option label="入驻技工" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="技工姓名：">
                    <el-input size="small" placeholder="请输入技工姓名" v-model="searchForm.artisan_name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：">
                    <el-input size="small" placeholder="请输入联系电话" v-model="searchForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="searchForm.status" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="已申退" :value="-2"></el-option>
                        <el-option label="正常" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考核状态：">
                    <el-select v-model="searchForm.check_status" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="待审核" :value="-1"></el-option>
                        <el-option label="未预约" :value="-2"></el-option>
                        <el-option label="已通过" :value="1"></el-option>
                        <el-option label="已驳回" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保险状态：">
                    <el-select v-model="searchForm.insurance_status" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="待审核" :value="-1"></el-option>
                        <el-option label="未预约" :value="-2"></el-option>
                        <el-option label="已通过" :value="1"></el-option>
                        <el-option label="已驳回" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否缴纳保证金：">
                    <el-select v-model="searchForm.is_earnest_money" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="否" :value="-1"></el-option>
                        <el-option label="是" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申请时间：">
                    <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="物业名称：">
                    <el-input size="small" placeholder="请输入物业名称" v-model="searchForm.property_name"></el-input>
                </el-form-item>
                <el-form-item label="小区名称：">
                    <el-input size="small" placeholder="请输入小区名称" v-model="searchForm.community_name"></el-input>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="type" label="类型" align="center" width="80">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.type == 1 ? '平台技工' : row.type == 2 ? '小区技工' : '入驻技工' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="property_name" label="所属物业" align="center" width="120">
                    <template v-slot="{ row }">
                        {{ row.property_name || '--' }}
                    </template>
                </el-table-column>
                <el-table-column prop="community_name" label="所属小区" align="center" width="120">
                    <template v-slot="{ row }">
                        {{ row.community_name || '--' }}
                    </template>
                </el-table-column>
                <el-table-column prop="artisan_name" label="技工姓名" align="center" width="80"></el-table-column>
                <el-table-column prop="mobile" label="联系电话" align="center" width="110"></el-table-column>
                <el-table-column prop="service_order_amount" label="服务订单总金额" align="center" width="120">
                    <template v-slot="{ row }">
                        <span>￥{{ row.service_order_amount }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="service_order_sum" label="服务订单总数" align="center" width="110"></el-table-column>
                <el-table-column prop="service_total_amount" label="服务收益总金额" align="center" width="120">
                    <template v-slot="{ row }">
                        <span>￥{{ row.service_total_amount }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" align="center" width="50">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.status == -2 ? '已申退' : '正常' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="rate" label="结算费率" align="center" width="80">
                    <template v-slot="{ row }">
                        {{ row.type == 3 ? row.rate + '%' : '--' }}
                    </template>
                </el-table-column>
                <el-table-column prop="check_status" label="考核状态" align="center" width="80">
                    <template v-slot="{ row }">
                        <span style="color: #999" v-if="row.type != 3">--</span>
                        <span style="color: blue" v-else-if="row.check_status == -1">待审核</span>
                        <span style="color: orange" v-else-if="row.check_status == -2">未预约</span>
                        <span style="color: blue" v-else-if="row.check_status == 1">已通过</span>
                        <span style="color: red" v-else>已驳回</span>
                    </template>
                </el-table-column>
                <el-table-column prop="insurance_status" label="保险状态" align="center" width="80">
                    <template v-slot="{ row }">
                        <span style="color: #999" v-if="row.type != 3">--</span>
                        <span style="color: blue" v-else-if="row.insurance_status == -1">待审核</span>
                        <span style="color: orange" v-else-if="row.insurance_status == -2">未预约</span>
                        <span style="color: blue" v-else-if="row.insurance_status == 1">已通过</span>
                        <span style="color: red" v-else>已驳回</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_earnest_money" label="是否缴纳保证金" align="center" width="120">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.type != 3 ? '--' : row.is_earnest_money == -1 ? '否' : '是' }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="earnest_money" label="保证金额" align="center" width="80">
                    <template v-slot="{ row }">
                        {{ row.type == 3 ? row.earnest_money : '--' }}
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="申请时间" width="200" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center" fixed="right">
                    <template v-slot="{ row }">
                        <el-button @click="toRightsDetail(row.id)" type="text" size="small">详情</el-button>
                        <div class="btn">
                            <el-button v-if="row.type == 3 && row.status == 1 && row.check_status == -1"
                                @click="getCheckInfo(row.id)" type="text" size="small">考核审核</el-button>
                            <el-button v-if="row.type == 3 && row.status == 1 && row.insurance_status == -1"
                                @click="getInsuranceInfo(row.id)" type="text" size="small">保险审核</el-button>
                            <el-button
                                v-if="row.type == 3 && row.status == 1 && row.is_earnest_money == 1 && row.earnest_money > 0"
                                @click="(showearnestMoneyDeduct = !0), (earnestMoneyDeductData.id = row.id)" type="text"
                                size="small">
                                扣除保证金
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
        <el-dialog title="考核审核" :visible.sync="showCheckInfo" width="600px">
            <el-form label-width="140px">
                <el-form-item label="考核时间：" style="width: 500px">
                    {{ checkInfo.check_time }}
                </el-form-item>
                <el-form-item label="考核地址：" style="width: 500px">
                    {{ checkInfo.subsidiary_name }}
                </el-form-item>
                <el-form-item label="审核结果：" required="true">
                    <el-radio-group v-model="checkData.check_status">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="2">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="确定考核时间：" style="width: 500px" v-if="checkData.check_status == 1" required="true">
                    <el-date-picker v-model="checkData.verify_check_time" type="datetime" placeholder="选择日期时间" align="right"
                        :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
                <el-form-item label="驳回原因：" v-if="checkData.check_status == 2" required="true">
                    <el-input type="textarea" :rows="4" v-model="checkData.remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showCheckInfo = !1">取 消</el-button>
                <el-button type="primary" @click="checkAudit">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="保险审核" :visible.sync="showInsuranceInfo" width="600px">
            <el-form label-width="140px">
                <el-form-item label="保险有效期：" style="width: 500px"></el-form-item>
                <el-form-item label="开始时间：" style="width: 500px">{{ insuranceInfo.start_time }}</el-form-item>
                <el-form-item label="结束时间：" style="width: 500px">{{ insuranceInfo.end_time }}</el-form-item>
                <el-form-item label="保险图片：">
                    <el-image style="width: 200px; height: 200px" :src="insurance_pictures[0]"
                        :preview-src-list="insurance_pictures"></el-image>
                </el-form-item>
                <el-form-item label="审核结果：" required="true">
                    <el-radio-group v-model="insuranceData.insurance_status">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="2">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="驳回原因：" v-if="insuranceData.insurance_status == 2" required="true">
                    <el-input type="textarea" :rows="4" v-model="insuranceData.remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showInsuranceInfo = !1">取 消</el-button>
                <el-button type="primary" @click="insuranceAudit">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="扣除保证金：" :visible.sync="showearnestMoneyDeduct" width="600px">
            <el-form label-width="140px">
                <el-form-item label="扣除金额：" required="true">
                    <el-input v-model="earnestMoneyDeductData.amount" type="number" step="0.01"><template
                            slot="append">元</template></el-input>
                </el-form-item>
                <el-form-item label="扣除原因：" required="true"><el-input type="textarea" :rows="4"
                        v-model="earnestMoneyDeductData.reason"></el-input></el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showearnestMoneyDeduct = !1">取 消</el-button>
                <el-button type="primary" @click="earnestMoneyDeduct">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                type: 0,
                artisan_name: '',
                mobile: '',
                status: 0,
                check_status: 0,
                insurance_status: 0,
                is_earnest_money: 0,
                start_time: '',
                end_time: '',
                property_name: '',
                community_name: '',
            },
            checkInfo: [],
            showCheckInfo: !1,
            checkData: {
                check_status: 1,
                verify_check_time: '',
                remark: '',
            },
            insuranceInfo: [],
            insurance_pictures: [],
            showInsuranceInfo: !1,
            insuranceData: {
                insurance_status: 1,
                remark: '',
            },
            showearnestMoneyDeduct: !1,
            earnestMoneyDeductData: {
                id: 0,
                amount: '',
                reason: '',
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '今天',
                        onClick (picker) {
                            picker.$emit('pick', new Date());
                        },
                    },
                    {
                        text: '昨天',
                        onClick (picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        },
                    },
                    {
                        text: '一周前',
                        onClick (picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        },
                    },
                ],
            }
        };
    },
    created () {
        this.getList();
    },
    methods: {
        search () {
            this.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                type: 0,
                artisan_name: '',
                mobile: '',
                status: 0,
                check_status: 0,
                insurance_status: 0,
                is_earnest_money: 0,
                start_time: '',
                end_time: '',
                property_name: '',
                community_name: '',
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.repair.artisan.list, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getCheckInfo (id) {
            this.showCheckInfo = !0;
            this.$axios.post(this.$api.repair.artisan.checkInfo, { id: id }).then(res => {
                if (res.code == 0) {
                    this.checkInfo = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        checkAudit () {
            if (!this.checkData.verify_check_time && this.checkData.check_status == 1) return this.$message.warning('请选择确定考核时间');
            if (!this.checkData.remark && this.checkData.check_status == 2) return this.$message.warning('请添加驳回原因');
            this.$axios
                .post(this.$api.repair.artisan.checkAudit, {
                    id: this.checkInfo.id,
                    check_status: this.checkData.check_status,
                    verify_check_time: this.checkData.verify_check_time,
                    remark: this.checkData.remark,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showCheckInfo = !1;
                        this.$message.success('处理成功');
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getInsuranceInfo (id) {
            this.showInsuranceInfo = !0;
            this.$axios.post(this.$api.repair.artisan.insuranceInfo, { id: id }).then(res => {
                if (res.code == 0) {
                    this.insuranceInfo = res.result;
                    this.insurance_pictures = [res.result.insurance_picture];
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        insuranceAudit () {
            if (!this.insuranceData.remark && this.insuranceData.insurance_status == 2) return this.$message.warning('请添加驳回原因');
            this.$axios
                .post(this.$api.repair.artisan.insuranceAudit, {
                    id: this.insuranceInfo.id,
                    insurance_status: this.insuranceData.insurance_status,
                    remark: this.insuranceData.remark,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showInsuranceInfo = !1;
                        this.$message.success('处理成功');
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        earnestMoneyDeduct () {
            if (!this.earnestMoneyDeductData.amount) return this.$message.warning('请添加扣除金额');
            if (!this.earnestMoneyDeductData.reason) return this.$message.warning('请添加扣除原因');
            this.$axios
                .post(this.$api.repair.artisan.earnestMoneyDeduct, {
                    id: this.earnestMoneyDeductData.id,
                    amount: this.earnestMoneyDeductData.amount,
                    reason: this.earnestMoneyDeductData.reason,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showearnestMoneyDeduct = !1;
                        this.$message.success('处理成功');
                        this.earnestMoneyDeductData = { id: 0, amount: 0, reason: '' };
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        toDetail () {
            this.$router.push({ path: '/user/repairAddSettled' });
        },
        toRightsDetail (id) {
            this.$router.push({ path: '/user/repairSettledDetail', query: { id: id } });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
